import React, {Suspense} from 'react';
import {connect} from 'react-redux';
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { Route } from "react-router"; 
import Header from './common/header/index';
// import Organs from './list/dbList/organs';
// import Home from 'view/home/index';
// import Hazards from './list/dbList/hazards';
// import Molecules from './list/dbList/molecules';
// import Interventions from './list/dbList/intervention';
// import Biomarkers from './list/dbList/biomarkers';
// import GetEntry from './list/dbList/getEntry';
import Loader from './common/elements/loader'

import AuthRoute from './common/elements/authRoute';
import PrivateRoute from './common/elements/privateRoute';

import Auth from 'view/public/auth/index'

import Administration from 'view/administration/index'

import BaseAlert from 'view/common/alert/baseAlert';

import Privacy from 'view/public/privacy'
import TOS from 'view/public/tos'

// import Connections from 'view/connections/index'

import RiskDashboard from "view/riskDashboard/index";
// import TestComponent from "./common/testComponent";

import { NotificationContainer } from './common/notification';
function MainView (props) {
    
    return (
        <Router>
            <NotificationContainer/>
            <Header/>
            <div className="mainContent px-5 md:px-6 lg:px-7 xl:px-8 pt-3 pb-48 md:pb-40">
                <div className={`border-b-2 border-uhblue-light`}>
                    <Suspense fallback={<Loader mainLoader={true} className={`small`}/>}>
                        <Switch>
                            
                            <PrivateRoute path="/administration" loggedIn={props.authentication.loggedIn}>
                                <Administration />
                            </PrivateRoute>
                            
                           

                            <PrivateRoute path="/risk" loggedIn={props.authentication.loggedIn}>
                                <RiskDashboard />
                            </PrivateRoute>

                           

                            <AuthRoute path="/auth" loggedIn={props.authentication.loggedIn}>
                                <Auth />
                            </AuthRoute>

                            <Route path='/privacy'>
                                <Privacy/>
                            </Route>
                            <Route path='/terms'>
                                <TOS/>
                            </Route>
                            <PrivateRoute path="/" loggedIn={props.authentication.loggedIn}>
                                <RiskDashboard/>
                            </PrivateRoute>
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </Router>    
    )
}

const mapStateToProps = state => ({
    authentication:state.authentication,
})

export default connect(mapStateToProps, {})(MainView);