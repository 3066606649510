
import React, { Fragment } from 'react'
import PropTypes from "prop-types";
import {buttonCommon, blueButton} from "./classNames";
import './button.css'

const Button = ({text,onClick,className, disabled, loading}) => {
    
    let loadingClass='';
    let isDisabled = false;
    let disabledClass='';

    if(loading && !disabled) {
        
        loadingClass="loading"
    }

    if(disabled) {
        isDisabled = true;
        disabledClass = 'disabled';
        
        loadingClass=''
    }
    const click = e => {

        if(onClick===undefined || !onClick) {
            return
        }
        e.preventDefault();
        if(loading||disabled) {
            return
        }
        
        onClick(e.target);
    }
    return (
      <button 
        onClick={click}
        className={`${buttonCommon} ${blueButton} ${className} ${disabledClass} ${loadingClass}`}
        disabled={isDisabled}
      >
          {text}
      </button>
        
    );
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default Button;