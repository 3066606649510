import React, { Fragment, useEffect, useState } from 'react';
import Button from 'view/common/elements/button';
import TextBox from 'view/common/elements/textbox';
import httpClient from 'httpClient';
import Select from 'view/common/elements/select';
import Notification from 'view/common/notification';
import ExistingLongitudinalHazards from './existingLongitudinalHazards';

const Container = (props) => {
  const [biomarkersLoading, setBiomarkersLoading] = useState(true);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [allBiomarkers, setAllBioMarkers] = useState([]);
  const [hazards, setHazards] = useState('');
  const [selectedBiomarker, setSelectedBiomarker] = useState({});
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [biomarkerMap, setBiomarkerMap] = useState({});

  const [biomarkerError, setBiomarkerError] = useState('');
  const [unitError, setUnitError] = useState('');
  const [hazardsError, setHazardsError] = useState('');

  const [dataSaving, setDataSaving] = useState(false);

  const [loadingExistingHazards, setLoadingExistingHazards] = useState(false);
  const [existingHazards, setExistingHazards] = useState([]);

  const [allDataSelected, setAllDataSelected] = useState(false);
  

  const formatAndSetHazards = (hazards) => {
    //format
    
    setHazards(hazards.replace(/\t+/g, ","))
  }

  const saveHazards = async () => {
    if(!selectedBiomarker || !selectedBiomarker.value) {
      setBiomarkerError("Please select a biomarker");
      return false;
    }
    if(!hazards) {
      setHazardsError("Please enter hazard rates");
      return false;
    }
    if(!selectedUnit || !selectedUnit.value) {
      setUnitError("Please enter a unit");
      return false;
    }
    const payload = {
      biomarker: selectedBiomarker.value,
      hazards: hazards,
      unit: selectedUnit.value,
    }

    
    setDataSaving(true)

    let response;
    try {
      response = await httpClient.post("/administration/v1/saveLongitudinalHazards", payload);
      if(!response.error) {
        Notification.success(`${response.linesUpdated} hazard rates were saved`)
      }
      loadExistingHazards()
    } catch (ex) {
      Notification.error("An error was encountered")
    }
    
    setDataSaving(false)
    console.log(response);
  }
  const loadBiomarkers = async () => {
    setBiomarkersLoading(true);
    const response = await httpClient.get("/administration/v1/getBiomarkers");
    if(response.error) {
      return;
    } else {
      console.log(response);
      setAllBioMarkers(response)
      setBiomarkersLoading(false);
      const bmMap = {};
      response.map(biomarker => {
        bmMap[biomarker.id] = biomarker
      })
      setBiomarkerMap(bmMap);
    }
  }

  const loadUnits = async () => {
    setUnitsLoading(true);
    const response = await httpClient.get("/administration/v1/getUnits");
    if(response.error) {
      return;
    } else {
      console.log(response);
      setUnits(response)
      setUnitsLoading(false);
    }
    
  }

  const onBiomarkerSelect = (v) => {
    if(!v) {
      return;
    }
    console.log(v);
    setSelectedBiomarker(v)
    console.log(biomarkerMap[v.value])
    setSelectedUnit({value:biomarkerMap[v.value].unitId, label:biomarkerMap[v.value].unit, })
  }

  const loadExistingHazards = async () => {
    console.log("loadExistingHazards", selectedBiomarker, selectedUnit);
    if(!selectedBiomarker || !selectedBiomarker.value) {
      return false;
    }
    
    if(!selectedUnit || !selectedUnit.value) {
      return false;
    }
    const payload = {
      biomarker: selectedBiomarker.value,
      unit: selectedUnit.value,
    }

    
    setLoadingExistingHazards(true)
    let response;

    try {
      response = await httpClient.post("/administration/v1/getLongitudinalHazards", payload);
      if(!response.error) {
        console.log(response)
        setExistingHazards(response.hazardRates)
      }
      
    } catch (ex) {
      Notification.error("An error was encountered")
    }
    setLoadingExistingHazards(false)
  
  }

  useEffect(()=>{
    loadBiomarkers()
    loadUnits()
  },[])

  useEffect(()=>{
    setDataLoaded(!unitsLoading && !biomarkersLoading);
  },[unitsLoading, biomarkersLoading])

  useEffect(()=>{
    if(selectedBiomarker && selectedBiomarker.value && selectedUnit && selectedUnit.value) {
      setAllDataSelected(true)
    } else {
      setAllDataSelected(false)
    }
    loadExistingHazards()
  },[selectedUnit, selectedBiomarker])

  return ( 
    <div >
      <div>
        <div className='w-1/2 inline-block'>
          <div className='text-3xl text-center mb-5'>Save Longitudinal Hazard Rates</div>
        </div>
        <div className='w-1/2 inline-block'></div>
      </div>
      <div className='flex items-stretch'>
        <div className='mb-2 w-1/2 inline-block'>
          <div className='mb-2 flex'>
            {!dataLoaded && <div> Loading ... </div>}
            {
              dataLoaded && 
              <Fragment>
                <div className='w-1/2 inline-block pr-1'>
                  <Select 
                    options={allBiomarkers} 
                    onChange={v => {onBiomarkerSelect(v)} }
                    labelKey="name"
                    valueKey="id"
                    
                    placeholder="Select Biomarker"
                    error={biomarkerError}
                    onFocus={() => setBiomarkerError('') }
                  />
                </div>
                <div className='w-1/2 inline-block pl-1'>
                  <Select 
                    options={units} 
                    onChange={v => {setSelectedUnit(v)} }
                    labelKey="name"
                    valueKey="id"
                    
                    placeholder="Select Unit"
                    value={selectedUnit}
                    error={unitError}
                    onFocus={() => setUnitError('') }
                  />
                </div>  
              </Fragment>
            }
          </div>
          <div>
          
            <TextBox
              content={hazards}
              name={"hazards"}
              onChange={formatAndSetHazards}
              error={hazardsError}
              onFocus={() => setHazardsError('') }
              placeholder="Enter Hazard rates as value, hazard rate eg {2.3, 1.01}"
              width="87"
              height="23"
            />

          </div>
          <Button 
            text={"Save"}
            onClick={saveHazards}
            loading={dataSaving}
            className={'blue'}
          />
        </div>
        <div className='mb-2 w-1/2 inline-block'>
          {
            loadingExistingHazards && <div>Loading ...</div>
          }
          {
            !loadingExistingHazards && allDataSelected && 
            <ExistingLongitudinalHazards 
              existingHazards={existingHazards}
              unit={selectedUnit?.label}
              biomarker={selectedBiomarker?.label}
            />
          }
        </div>
      </div>
    </div>
  )  
}

export default Container;